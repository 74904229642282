<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">联网设备</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.hardware_number" placeholder="硬件编号" />
				</a-form-model-item>
				<a-form-model-item label="注册状态">
					<a-select placeholder="请选择" v-model="query.status" @change="selectChange">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="-1" :key="-1">
							未注册
						</a-select-option>
						<a-select-option :value="1" :key="1">
							已注册
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
			<a-row style="margin-top: 20px">
				<a-form-model-item label="设备状态">
					<a-checkbox-group v-model="checkValue" name="checkboxgroup" :options="checkOptions"
						@change="changeCheck" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page, total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="status" slot-scope="text, record">
				<a-tag :color="record.status === 1 ? '#74CE78' : '#757776'">
					{{ record.status === 1 ? '已注册' : '未注册' }}
				</a-tag>
			</span>
			<span slot="work_status" slot-scope="text, record">
				<a-tag
					:color="record.work_status === '01' ? '#74CE78' : (record.work_status === '02' ? '#faad14' : '#757776')">
					{{ record.work_status === '01' ? '输液中' : (record.work_status === '02' ? '暂 停' : '待租用') }}
				</a-tag>
			</span>
			<span slot="equipment_status" slot-scope="text, record">
				<a-tag :color="record.equipment_status === 1 ? '#74CE78' : '#757776'">
					{{ record.equipment_status === 1 ? '在线' : '离线' }}
				</a-tag>
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			Rules: [],
			pagetotal: 0,
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '硬件编号',
				dataIndex: 'hardware_number'
			},
			{
				title: '注册状态',
				scopedSlots: {
					customRender: 'status'
				}
			},
			{
				title: '在线状态',
				scopedSlots: {
					customRender: 'equipment_status'
				}
			},
			{
				title: '租用状态',
				scopedSlots: {
					customRender: 'work_status'
				}
			},
			{
				title: '首次联网时间',
				dataIndex: 'create_at_str'
			},

			],
			data: [],
			checkValue: [],
			checkOptions: [
				{
					label: '在线',
					value: 1
				},
				{
					label: '离线',
					value: 2
				},
				{
					label: '租用中',
					value: 3
				},
				{
					label: '未租用',
					value: 4
				}
			],
		}
	},
	created() {
		this.getList()
	},
	methods: {
		changeCheck(value) {
			this.query.page = 1
			this.query.type = value
			this.getList()
		},
		handleCancel() { },
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/equipment/hardwareOnlineList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.getList();
			console.log(query)
		},
		selectChange() {
			this.query.page = 1
			this.getList();
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
